<template>
  <v-card tile class="pa-0">
    <v-list-item class="conversations-title">
      <v-btn color="black mr-3" small icon @click="redirectToDashboard">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-list-item-content>
        <v-list-item-title class="text-h6 py-6">
          Conversations
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-navigation-drawer width="100%" permanent>
      <v-list dense nav class="conversations-list px-0">
        <v-list-item-group>
          <v-list-item
            v-for="item in conversations"
            :key="item.title"
            link
            class="pa-0"
          >
            <v-list-item-content
              :id="`conversation-${item.id}`"
              :ref="`conversation-${item.id}`"
              :class="{ 'conversation active': activeItem === item.id }"
              @click="selectConversation(item)"
            >
              <v-list-item-title>{{ item.user.username }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.lastMessage ? item.lastMessage.content : ""
              }}</v-list-item-subtitle>
              <p>
                {{ item.formatDatetime }}
              </p>
              <div
                v-show="item.lastMessage ? !item.lastMessage.is_admin : null"
                class="status"
              ></div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { format, fromUnixTime } from "date-fns";
export default {
  name: "TheConversations",
  props: {
    items: {
      type: Array,
    },
    activeItem: {
      type: Number,
    },
  },
  data: () => ({
    msg: "",
    currentConversation: "",
    eventSource: null,
  }),
  computed: {
    conversations() {
      this.items.map((item) => {
        if (item.lastMessage?.files.length) {
          item.lastMessage.content = "file";
        } else {
          return item.lastMessage?.content;
        }
      });
      let conv = this.items;
      conv.map((item) => {
        item.formatDatetime = item.lastMessage
          ? format(
              fromUnixTime(item.lastMessage.datetime),
              "MM/dd/yyyy - hh:mm:ss aa"
            )
          : null;
      });
      return conv.sort((a, b) => {
        let d1 = b.lastMessage ? b.lastMessage.datetime : 0;
        let d2 = a.lastMessage ? a.lastMessage.datetime : 0;
        return d1 - d2;
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.scrollToSelectedConversation();
    }, 1500);
  },
  async created() {
    if (this.eventSource) {
      await this.eventSource.close();
    }
    await this.subscribeToConversations();
  },
  methods: {
    scrollToSelectedConversation() {
      const element = this.$refs[`conversation-${this.activeItem}`][0];
      if (element) {
        element.scrollIntoView({ behavior: "smooth", inline: "start" });
      }
    },
    redirectToDashboard() {
      window.location.href = this.$vars.BACKEND_BASE_URL + "/admin/";
    },

    selectConversation(conversations) {
      this.$emit("selectConversation", conversations);
    },

    subscribeToConversations() {
      let url = new URL(this.$vars.MERCURE_BASE_URL);
      url.searchParams.append("topic", "/chat/conversations");
      this.eventSource = new EventSource(url, {
        withCredentials: true,
      });
      this.eventSource.onmessage = async (event) => {
        const newMessage = JSON.parse(event.data);
        const { conversation, user, ...rest } = newMessage;
        const payload = {
          message: {
            ...rest,
          },
          user: user,
          id: conversation,
        };
        this.$emit("updateConversations", payload);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.conversations-title {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.conversations-list {
  height: 90vh;
}

.active {
  color: #2f4ea5 !important;
  background-color: #e5e5e5 !important;
}

.status {
  position: absolute;
  right: 14px;
  bottom: 13px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #cd3023;
  box-shadow: 0 0 4px 4px #cd3023;
}
</style>
